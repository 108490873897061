<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" >

      <div class="d-flex flex-row-reverse">
        <b-button variant="primary"
                  v-if="!editable"
                  v-on:click="setEditable()">Edit</b-button>
      </div>

      <br/>


      <inputTextform :edit="false"
                     v-bind:label="'PMI CODE'"
                     v-bind:var_name="'pmi_code'"
                     v-bind:var_value="form.pmi_code"
                     v-bind:optional="false"
                     @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="false"
                     v-bind:label="'PMI NAME'"
                     v-bind:var_name="'pmi_name'"
                     v-bind:var_value="form.pmi_name"
                     v-bind:optional="false"
                     @onChangedValue="onChangedValue"
      />


      <inputNumberform :edit="editable"
                       v-bind:label="'MIN. QUANTITY'"
                       v-bind:var_name="'min_quantity'"
                       v-bind:var_value="form.min_quantity"
                       v-bind:optional="true"
                       @onChangedValue="onChangedValue"
      />


      <inputNumberform :edit="editable"
                       v-bind:label="'BOOKING'"
                       v-bind:var_name="'booking'"
                       v-bind:var_value="form.booking"
                       v-bind:optional="true"
                       @onChangedValue="onChangedValue"
      />


      <inputNumberform :edit="editable"
                       v-bind:label="'ORDERING QUANTITY'"
                       v-bind:var_name="'ordering_quantity'"
                       v-bind:var_value="form.ordering_quantity"
                       v-bind:optional="true"
                       @onChangedValue="onChangedValue"
      />

      <inputDate :edit="editable"
                 v-bind:label="'ORDERING DATE'"
                 v-bind:var_name="'ordering_date'"
                 v-bind:var_value="form.ordering_date"
                 v-bind:optional="true"
                 @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                     v-bind:label="'NUMBER OF PO'"
                     v-bind:var_name="'po_number'"
                     v-bind:var_value="form.po_number"
                     v-bind:optional="true"
                     @onChangedValue="onChangedValue"
      />

      <inputTextform :edit="editable"
                     v-bind:label="'SUPPLIER'"
                     v-bind:var_name="'supplier'"
                     v-bind:var_value="form.supplier"
                     v-bind:optional="true"
                     @onChangedValue="onChangedValue"
      />

      <br/>

      <div class="d-flex flex-row-reverse" v-if="!user.roles.isViewer">

        <b-button :v-if="editable" type="submit" :disabled="!validation" variant="primary">UPDATE</b-button>

        <b-button :v-if="editable" class="second-button" type="reset" variant="danger">CLEAR</b-button>

        <b-button :v-if="editable"  class="pull-left" v-on:click="clearOrder()">CLEAR ORDER</b-button>

        <b-button :v-if="editable"  class="pull-left second-button" variant="primary" v-on:click="deleteBooking()">DELETE REFERENCE</b-button>

      </div>
    </b-form>
  </div>
</template>

<style scoped>

.form-control {
  padding: 2px;
}

.container {
  padding-bottom: 5px;
}

.pull-left{
  alignment: left;
}

.second-button {
  margin-right: 10px;
}
</style>

<script>


import { mapState, mapGetters } from 'vuex'
import utils from '@/utils'
import inputTextform from '@/views/components/forms/Form.input.text'
import inputNumberform from '@/views/components/forms/Form.input.number'
import inputDate from '@/views/components/forms/Form.input.date'


export default {
  components: {
    inputTextform,
    inputNumberform,
    inputDate
  },
  props: {
    edit: Boolean,
    data: Object,
    user: String
  },
  data() {
    return {
      editable: this.edit,
      form: {
        pmi_code: this.data.pmi_code,
        pmi_name: this.data.pmi_name,
        min_quantity: Number(this.data.min_quantity) || 0,
        booking: Number(this.data.booking) || 0,
        ordering_quantity: Number(this.data.ordering_quantity) || 0,
        ordering_date: this.data.ordering_date,
        po_number: this.data.po_number,
        supplier: this.data.supplier
      },
      show: true
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['user']),
    validation() {
      return utils.isNotUndefined(this.form.min_quantity) && this.form.min_quantity > 0
          // && utils.isNotUndefined(this.form.booking) && this.form.booking > 0
    }
  },
  mounted() {
  },
  methods: {
    setEditable(){
      this.editable = true;
    },
    clearOrder() {
      this.form.ordering_quantity=0
      this.form.ordering_date=null
      this.form.po_number = null
    },
    deleteBooking(){
      const payload = { form: this.form, previous: this.data, user: this.user, reason: ''}
      this.$store.dispatch('deleteBooking', payload);
      if (!utils.isEmpty(this.data)) this.$emit('closeModal');
    },
    onChangedValue(v){
      this.form[v.name] = v.value
    },
    onSubmit(event) {
      event.preventDefault()
      const payload = { form: this.form, previous: this.data, user: this.user, reason: ''}
      this.$store.dispatch('saveBooking', payload);
      if (!utils.isEmpty(this.data)) this.$emit('closeModal');

    },
    onReset(event) {
      event.preventDefault()
      this.form.pmi_code=null
      this.form.pmi_name=null
      this.form.min_quantity=0
      this.form.booking=0
      this.form.ordering_quantity=0
      this.form.ordering_date=null
      this.form.supplier=null
      this.form.po_number=null
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

  }
}
</script>
