<template>
  <b-container fluid>

    <loading loader="spinner"
             :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"></loading>

    <!-- Info BOOKING modal -->
    <b-modal
        :id="bookingModal.id"
        :title="bookingModal.title"
        :hide-header="true"
        :hide-footer="true"
        @hide="resetBookingModal"

    >

      <BookingForm :edit="bookingModal.editable"
                  :data="bookingModal.content"
                  :user="bookingModal.user"
                  v-on:closeModal="closeBooking"/>
    </b-modal>


    <!-- Info FLAVOR modal -->
    <b-modal
        :id="flavorModal.id"
        :title="flavorModal.title"
        :hide-header="true"
        :hide-footer="true"
        @hide="resetFlavorModal"

    >

      <FlavorForm :edit="flavorModal.editable"
                  :data="flavorModal.content"
                  :user="flavorModal.user"
                  v-on:refreshTable="refreshTable"
                  v-on:closeModal="closeFlavor"/>
    </b-modal>

    <b-table

        :items="filtered"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"

        stacked="md"
        show-empty
        small
        striped
        hover

        @filtered="onFiltered"
    >
      <template #head()="data"  >
        <span class="text-info">{{ data.label.toUpperCase() }}</span>
      </template>

      <template slot="top-row" slot-scope="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <input class="filter-input"
                 v-model="filters[field.key]"
                 :hidden="field.key=='actions'">
        </td>
      </template>

      <template #cell(actions)="row">
        <b-col>
          <b-icon-pencil  class="cursor-pointer"
                          @click="editBooking(row.item, row.index, $event.target)"></b-icon-pencil>
        </b-col>
      </template>

      <template #cell(status)="row">
        <b-col :class="getStatusClass(row.item.status)" >{{row.item.status}}</b-col>
      </template>


      <template #cell(ordering_quantity)="row">
        <b-col>{{row.item.ordering_quantity}}
          <b-icon-bag v-if="row.item.ordering_quantity > 0"  class="cursor-pointer"
                         @click="createFlavor(row.item, row.index, $event.target)"></b-icon-bag>
        </b-col>
      </template>


    </b-table>



    <!-- PAGINATION -->

    <div class="d-flex justify-content-center">

      <label class="buttons cursor-pointer pull-left">
        Upload Booking file &nbsp; <b-icon-upload />
        <input type="file" @change="onUploadExcel($event)" hidden/>
      </label>

      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          size="sm"
          align="fill"
      ></b-pagination>


      &nbsp;&nbsp;&nbsp;&nbsp;

      Per page

      &nbsp;

      <b-form-select style="height: 25px"
                     v-model="perPage"
                     :options="pageOptions"
                     size="sm"
      ></b-form-select>
    </div>
  </b-container>
</template>


<style>
.sr-only {display: none;}
.filter-input {
  width: 110px !important;
}

.status-ok {  color: green;  font-weight:bold }
.status-in-order {  color: orange; font-weight:bold }
.status-to-order {  color: red;  font-weight:bold}

.cursor-pointer{
  cursor: pointer;
}

.pull-left {
  float:left;
  margin-right: 100px;
}

.page-item.active .page-link {
  height: 31px;
}

</style>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BookingForm  from '@/views/components/Booking.Form'
import FlavorForm from '@/views/components/Flavor.Form'
import utils from "@/utils";
import moment from "moment";
import * as XLSX from "xlsx"; // https://stackoverflow.com/questions/72111794/xlsx-is-undefined-when-trying-to-export-excel-sheet

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'


function getItemStatus(item){

  const min_quantity = item.min_quantity || 0;
  const ordering_quantity = item.ordering_quantity || 0;
  const booking_quantity = item.booking || 0;
  const quantity_not_expired_3months = item.quantity_not_expired_3months || 0;


  if (ordering_quantity > 0) {
        if (booking_quantity > 0) {
            return 'IN Order Urgent';
        }
        else {
            return 'IN Order';
        }
    }

  if (quantity_not_expired_3months - booking_quantity <= min_quantity) {
     if (booking_quantity > 0) {
            return 'TO Order Urgent';
        }
        else {
            return 'TO Order';
        }
  }

  return 'OK';

}

import UserInfoStore from '../../store/user';

export default {
  components: {
    BookingForm,
    FlavorForm,
    loading: VueLoading
  },
  data() {
    return {
      isLoading: true,
      userInfo: UserInfoStore.state.cognitoInfo,
      booking_flatlist: [],
      selected: [],
      fields : [
        { key: 'actions', label: '' },
        { key: 'pmi_code', label: 'PMI CODE', sortable: true },
        { key: 'pmi_name', label: 'PMI NAME', sortable: true },
        { key: 'min_quantity', label: 'MIN.QUANTITY (gr)', sortable: true },
        { key: 'booking', label: 'BOOKING (gr)', sortable: true },
        { key: 'quantity_expired', label: 'Q. EXPIRED', sortable: true },
        { key: 'quantity_not_expired', label: 'Q. NOT EXPIRED', sortable: true },
        { key: 'quantity_not_expired_3months', label: 'Q. NOT EXP. 3 MONTHS', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'ordering_quantity', label: 'ORDERING (gr)', sortable: true },
        { key: 'ordering_date', label: 'ORDER. DATE', sortable: true, formatter: 'getFormatDate' },
        { key: 'po_number', label: 'NUMBER of PO', sortable: true },
        { key: 'supplier', label: 'SUPPLIER', sortable: true }

      ],
      filters: {
        pmi_code: '',
        pmi_name: '',
        min_quantity: ''  ,
        booking: '',
        quantity_not_expired: '',
        quantity_not_expired_3months: '',
        status: '',
        ordering_quantity: '',
        ordering_date: '',
        po_number: '',
        supplier: ''
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 1000,
      pageOptions: [15, 30, 60, 120, { value: 1000, text: "Show a lot" }],
      sortBy: 'pmi_code',
      sortDesc: false,
      sortDirection: 'asc',
      bookingModal: {
        id: 'booking-modal',
        title: '',
        content: {},
        user: '',
        editable: false
      },
      flavorModal: {
        id: 'flavor-modal',
        title: '',
        content: {},
        booking: {},
        user: '',
        editable: false
      }
    }
  },
  computed: {
    ...mapState(['user', 'booking_list']),
    ...mapGetters(['user','booking_list']),
    filtered() {

      this.flatData(); // carefully if the data from origin is updated often

      const filtered = this.booking_flatlist.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()) ||
            String(this.getFormatDate(item[key])).toLowerCase().includes(this.filters[key].toLowerCase())
        )
      })

      return filtered.length > 0 ? filtered : [{
        pmi_code: '',
        pmi_name: '',
        min_quantity: '' ,
        booking: '',
        quantity_not_expired: '',
        quantity_not_expired_3months: '',
        status: '',
        ordering_quantity: '',
        ordering_date: '',
        po_number: '',
        supplier: ''
      }]
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.booking_list.length
    this.getBookingLists().then(() => { this.isLoading=false; })
    this.getUser()
  },
  methods: {
    ...mapActions(['getUser', 'getBookingLists']),
    getFormatDate(date){
      return (utils.isNotUndefined(date))? moment(date, 'YYYY-MM-DD').format('DD MMM YYYY'): '';
    },
    closeBooking() {
      this.$root.$emit('bv::hide::modal', this.bookingModal.id);
      this.refreshTable();
    },
    // closeFlavor(quantity_received) {
    closeFlavor() {
      let _item =  Object.assign({}, this.flavorModal.booking, {});
      //_item.ordering_quantity = _item.ordering_quantity - quantity_received;
      _item.ordering_quantity = 0;
      const payload = { form: _item, previous: this.flavorModal.booking, user: this.user, reason: ''}
      this.$store.dispatch('saveBooking', payload);
      this.$root.$emit('bv::hide::modal', this.flavorModal.id);
      this.refreshTable();
    },
    refreshTable(){
      setTimeout(() => {
        this.booking_list = this.getBookingLists();
        this.totalRows = this.booking_list.length;
        this.$forceUpdate();
        //this.$refs.table.refresh();
      },1000, this);
    },
    editBooking(item, index, button) {
      this.bookingModal.content = item
      this.bookingModal.editable = true
      this.bookingModal.user = this.user.user
      this.$root.$emit('bv::show::modal', this.bookingModal.id, button)
    },
    createFlavor(item, index, button){

      if (utils.isEmpty(item.pmi_code) || utils.isEmpty(item.pmi_name)) return;

      this.flavorModal.content = { pmi_code: item.pmi_code, pmi_name: item.pmi_name }
      this.flavorModal.booking = item
      this.flavorModal.editable = true
      this.flavorModal.user = this.user.user
      this.$root.$emit('bv::show::modal', this.flavorModal.id, button)
    },
    resetBookingModal() {
      this.bookingModal.title = ''
      this.bookingModal.content = {}
      this.bookingModal.user = ''
    },
    resetFlavorModal() {
      this.flavorModal.title = ''
      this.flavorModal.content = {}
      this.flavorModal.user = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getStatusClass(value){
      if (value.toLowerCase() == 'in order' || value.toLowerCase() == 'in order urgent') return 'status-in-order';
      if (value.toLowerCase() == 'to order' || value.toLowerCase() == 'to order urgent') return 'status-to-order';
      return 'status-ok';
    },
    onUploadExcel(event) {
      console.log('...processing excel ');
      console.log(event);

      const file = event.target.files[0];
      const reader = new FileReader();

      console.log('---reader object :')
      console.log(reader);

      reader.onload = (event) => {

        const bstr = event.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1}); // convert to array of arrays, instead json property

        data.shift(); // remove header
        console.log(data);
        this.$store.dispatch('saveListOfBookings', data);
        this.refreshTable();

      }
      reader.readAsBinaryString(file);

    },
    flatData(){

        this.booking_flatlist = this.booking_list.map(function (item) {
          const ident = { _id : item._id,
                          pmi_code: item.pmi_code,
                          pmi_name: item.pmi_name,
                          quantity_expired: item.quantity_expired,
                          quantity_not_expired: item.quantity_not_expired,
                          quantity_not_expired_3months: item.quantity_not_expired_3months,
                          status : null};

          const values = (utils.isUndefined(item.values)) ? {} : item.values[0];
          const flatItem = Object.assign({}, values, ident);

          flatItem.status = getItemStatus(flatItem);

          return flatItem
        })

    }
  }
}
</script>

